import { createI18n } from 'vue-i18n';
import uz from '../locales/uz';
import ru from '../locales/ru';
import en from '../locales/en';
const i18n = createI18n({
    legacy: false,
    locale: 'ru',
    fallbackLocale: 'ru',
    messages: { uz, ru },
});

export default i18n