import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import mobileMixin from './utils/mobileMixin';
import axiosInstance from './plugins/axios'
//  =======  Dev depensies packages ======
import VueAwesomePaginate from "vue-awesome-paginate";
import ToastPlugin from 'vue-toast-notification';
import Moment from './plugins/moment.js'
import i18n from './plugins/i18n'
// ======  Font awesome icons  ===========
import { icons } from './plugins/fontawesome'
import { loadFonts } from './plugins/webfontloader'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

loadFonts()
library.add(Object.values(icons))

// ======  Style files  ===========
import './assets/styles/login.scss'
import './assets/styles/main.scss'
import './assets/styles/sidebar.scss'
import './assets/styles/navbar.scss'
import './assets/styles/clients.scss'
import './assets/styles/responsive.scss'
import './assets/styles/roll-calls.scss'
import './assets/styles/tasks.scss'
import 'vue-toast-notification/dist/theme-bootstrap.css';
import "vue-awesome-paginate/dist/style.css";

// Componenets
import Filters from './components/Filters.vue'
import Table from './components/Table.vue'
import BaseModal from './components/Modals/BaseModal.vue'
import Checkbox from './components/Checkbox.vue'
import Pagination from './components/Pagination.vue'
import EditModal from './components/Modals/EditModal.vue'
import DeleteModal from './components/Modals/DeleteModal.vue'



createApp(App)
    .component("Filters", Filters)
    .component("BaseModal", BaseModal)
    .component("DeleteModal", DeleteModal)
    .component("Table", Table)
    .component("Checkbox", Checkbox)
    .component("Pagination", Pagination)
    .component("EditModal", EditModal)
    .provide('axios', axiosInstance)
    .component("font-awesome-icon", FontAwesomeIcon)
    .component('QuillEditor', QuillEditor)
    .mixin(mobileMixin)
    .use(i18n)
    .use(router)
    .use(store)
    .use(ToastPlugin)
    .use(vuetify)
    .use(Moment)
    .use(VueAwesomePaginate)
    .mount('#app')