// src/plugins/axios.js
import axios from 'axios';
import store from '@/store'; // Make sure the store is imported correctly

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
});

// Request Interceptor
axiosInstance.interceptors.request.use(function(config) {
        const token = store.getters.authToken;

        if (token && !config.url.includes('/auth/v1/admin')) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function(err) {
        return Promise.reject(err);
    });

// Check local storage and set the Authorization header
const token = JSON.parse(localStorage.getItem('admin_token'));
if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
if (token) {
    try {
        let base64Url = token.split('.')[1];
        base64Url = base64Url.replace(/-/g, '+').replace(/_/g, '/');

        switch (base64Url.length % 4) {
            case 2:
                base64Url += '==';
                break;
            case 3:
                base64Url += '=';
                break;
        }

        const tokenData = JSON.parse(atob(base64Url));
        const tokenExpiration = tokenData.exp * 1000;
        const currentTimestamp = new Date().getTime();

        if (tokenExpiration < currentTimestamp) {
            localStorage.removeItem('token');
        }
    } catch (error) {
        console.error('Error parsing token:', error);
        localStorage.removeItem('token');
    }
}


export default axiosInstance;