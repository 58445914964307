<template>
  <v-app>
    <div class="mx-0 fill-height d-flex flex main full-width">
      <router-view name="sidebar" />
      <div class="d-flex flex-column full-width content">
        <router-view name="navbar" />
        <router-view />
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  inject: ["axios"],
  name: "App",
  methods: {
     isTokenExpired(token){
      if (!token) {
        return true;
      }
    const tokenPayload = JSON.parse(atob(token.split('.')[1]));
    const expirationTime = tokenPayload.exp * 1000; 
    const currentTime = Date.now();
    return currentTime > expirationTime;
},

  },
  // mounted() {
  //   const token = localStorage.getItem('admin_token');
  //   if (this.isTokenExpired(token)) {
  //     localStorage.removeItem('admin_token');
  // }
  // },
};
</script>
