export default {
    sidebar: {
        posts: 'Посты',
        list_posts: "Список постов",
        list_comments: "Cписок комментарии",
        create_post: "Создать новый пост",
        edit_post: "Изменить пост",
        edit_video: "Изменить видео",
        list_heroes: "Список героев",
        create_hero: "Создать новый пост героя",
        edit_hero: "Изменить пост герои",
        unmoderated_posts: "Немодерируемый посты",
        topics: "Основные темы",
        edit_topic: "Изменить тему",
        list_topics: "Список тем",
        create_topic: "Создать новую тему",
        list_videos: "Список видео",
        create_video: "Создать новое видео",
        videos: "Видео",
        faq: "FAQ",
        list_questions: "Список вопросов",
        create_question: "Создать новый вопрос",
        edit_question: "Изменить вопрос",
        about: "О компании",
        heroes: "Наши герои",
        navigations: "Навигационные ссылки",
        list_navigations: "Список навигационные ссылки",
        create_navigation: "Cоздать новую ссылку",
        edit_navigation: "Изменить навигационные ссылки",
        clients: "Клиенты",
        disclaimer: "Дисклаймер",
        policy: "Политика конфиденциальности",
        advertising_policy: "Рекламная политика",
        questions: "Вопросы",
        advertising_collaboration: "Рекламная сотрудничество",
        lead_magnet: "Лид-магнит",
        lead_magnet_file: "Лид-магнит файл",
        lead_magnet_text: "Лид-магнит тексты",
        client__likes: "Список понравившийся посты",
        client__comments: "Список коментарии",
        post__id: "Подробный пост"

    },
    profile: {
        profile: "Профиль",
        logout: "Выйти"
    }
}