<template>
    <div class="delete-modal">
      <BaseModal
        :modalState="editModalStatus"
        :title="modalTitle"
        @closeModal="closeModal"
      >
        <h1 class="modal__title">{{ modalSubtitle }}</h1>
        <div class="delete-modal-buttons">
          <button @click="cancelItem" class="cancel">Отмена</button>
          <button @click="deleteItem" class="delete">Удалить</button>
        </div>
      </BaseModal>
    </div>
  </template>
  
  <script>
  export default {
      props: ["editModalStatus"],
      data() {
      return {
        modalTitle: "Изменить выбранный элемент",
        modalSubtitle: "Вы действительно хотите удалить выбранный элемент?",
      };
    },
    methods: {
      closeModal(){
          this.$emit("close-delete-modal");
      },
      cancelItem(){
          this.$emit("close-delete-modal");
      },
      deleteItem(){
          this.$emit("delete-item");
      }
    }
  };
  </script>
  
  <style lang="scss">
  .delete-modal{
      &-buttons{
          display: flex;
          margin: 0 auto;
          margin-top: 2.5rem;
          justify-content: space-between;
          width: 90%;
          button{
              height: 3rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0.3rem;
              padding: 1rem;
              width: 45%;
          }
          .cancel{
              background: #8388A4;
          }
          .delete{
              background: #ED5E68;
          }
      }
  }
  </style>