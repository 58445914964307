<template>
  <div>
    <div class="header">
      <div v-if="statuses" class="filters">
        <v-text-field
          :label="$t('search')"
          variant="outlined"
          v-model="searchQuery"
        ></v-text-field>
        <v-select
          clearable
          :label="$t('table.category')"
          variant="outlined"
          v-model="selectedStatus"
          :items="statuses"
        ></v-select>
      </div>
    </div>
    <v-table v-if="!mobile" class="table" hide-default-footer>
      <thead class="bg-theadColor">
        <tr>
          <th class="text-center" v-for="header in headers" :key="header">
            {{ header }}
            <!-- {{ $t("table." + header) }} -->
          </th>
        </tr>
      </thead>
      <tbody>
        <slot name="tr"></slot>
      </tbody>
    </v-table>
    <!-- Mobile -->
    <div v-else class="table-mobile">
      <slot name="mobile"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: ["headers", "tr", "title", "statuses", "categories"],
  data() {
    return {
      selectedStatus: null,
    };
  },
};
</script>
<style  lang="scss">
.v-table {
  margin-top: 2rem;
  border-radius: 0.428rem;
  width: 100%;
  thead {
    tr {
      height: 4.8rem !important;
      td {
        justify-content: center;
        align-items: flex-start;
      }
      th {
        font-size: 0.8rem;
        font-weight: 800;
      }
      th:first-child {
        text-align: start !important;
      }
    }
  }
  tbody {
    tr {
      font-size: 0.8rem;
      td {
        height: 5.2rem !important;
      }
      .text{
        line-break: anywhere;
      }
      .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.8rem;
        div{
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
      td:first-child {
        text-align: start !important;
      }
    }
  }
}
</style>
