import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
        path: "/",
        redirect: "/login"
    },
    {
        path: '/login',
        name: 'sidebar.login',
        component: () =>
            import ('../views/Login/index.vue')
    },
    {
        path: '/posts',
        name: 'sidebar.list_posts',
        components: {
            default: () =>
                import ('../views/Posts/PostList.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/posts/:id',
        name: 'sidebar.post__id',
        components: {
            default: () =>
                import ('../views/Posts/PostId.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/posts/comments/:id',
        name: 'sidebar.list_comments',
        components: {
            default: () =>
                import ('../views/Posts/Comments.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/posts/create-post',
        name: 'sidebar.create_post',
        components: {
            default: () =>
                import ('../views/Posts/CreatePost.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/posts/edit-post/:id',
        name: 'sidebar.edit_post',
        components: {
            default: () =>
                import ('../views/Posts/EditPost.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/posts/unmoderated-posts',
        name: 'sidebar.unmoderated_posts',
        components: {
            default: () =>
                import ('../views/Posts/Unmoderated.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/topics',
        name: 'sidebar.list_topics',
        components: {
            default: () =>
                import ('../views/Topics/TopicsList.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/topics/create-topic',
        name: 'sidebar.create_topic',
        components: {
            default: () =>
                import ('../views/Topics/CreateTopic.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/topics/edit-topic/:id',
        name: 'sidebar.edit_topic',
        components: {
            default: () =>
                import ('../views/Topics/EditTopic.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/videos',
        name: 'sidebar.videos',
        components: {
            default: () =>
                import ('../views/Videos/VideosList.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/videos/edit-video/:id',
        name: 'sidebar.edit_video',
        components: {
            default: () =>
                import ('../views/Videos/EditVideo.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/videos/create-video',
        name: 'sidebar.create_video',
        components: {
            default: () =>
                import ('../views/Videos/CreateVideo.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/faq',
        name: 'sidebar.faq',
        components: {
            default: () =>
                import ('../views/Faq/QuestionsList.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/faq/edit-question/:id',
        name: 'sidebar.edit_question',
        components: {
            default: () =>
                import ('../views/Faq/EditQuestion.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/faq/create-question',
        name: 'sidebar.create_question',
        components: {
            default: () =>
                import ('../views/Faq/CreateQuestion.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/about',
        name: 'sidebar.about',
        components: {
            default: () =>
                import ('../views/About/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/heroes',
        name: 'sidebar.heroes',
        components: {
            default: () =>
                import ('../views/Heroes/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/heroes/create-hero',
        name: 'sidebar.create_hero',
        components: {
            default: () =>
                import ('../views/Heroes/CreateHeroes.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/heroes/edit-hero/:id',
        name: 'sidebar.edit_hero',
        components: {
            default: () =>
                import ('../views/Heroes/EditHeroes.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/navigations',
        name: 'sidebar.navigations',
        components: {
            default: () =>
                import ('../views/Navigations/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/navigations/create-navigation',
        name: 'sidebar.create_navigation',
        components: {
            default: () =>
                import ('../views/Navigations/CreateNavigation.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/navigations/edit-navigation/:id',
        name: 'sidebar.edit_navigation',
        components: {
            default: () =>
                import ('../views/Navigations/EditNavigation'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/questions',
        name: 'sidebar.questions',
        components: {
            default: () =>
                import ('../views/Questions/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/clients',
        name: 'sidebar.clients',
        components: {
            default: () =>
                import ('../views/Clients/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/clients/likes/:id',
        name: 'sidebar.client__likes',
        components: {
            default: () =>
                import ('../views/Clients/Likes.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/clients/comments/:id',
        name: 'sidebar.client__comments',
        components: {
            default: () =>
                import ('../views/Clients/Comments.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/lead-magnet/file',
        name: 'sidebar.lead_magnet_file',
        components: {
            default: () =>
                import ('../views/LeadMagnet/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/lead-magnet/text',
        name: 'sidebar.lead_magnet_text',
        components: {
            default: () =>
                import ('../views/LeadMagnet/LeadText.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/advertising-collaboration',
        name: 'sidebar.advertising_collaboration',
        components: {
            default: () =>
                import ('../views/Partnership/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/profile',
        name: 'profile.profile',
        components: {
            default: () =>
                import ('../views/Profile/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/disclaimer',
        name: 'sidebar.disclaimer',
        components: {
            default: () =>
                import ('../views/Disclaimer/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/policy',
        name: 'sidebar.policy',
        components: {
            default: () =>
                import ('../views/Policy/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/advertising-policy',
        name: 'sidebar.advertising_policy',
        components: {
            default: () =>
                import ('../views/Advertisment/index.vue'),
            sidebar: () =>
                import ('../components/Sidebar.vue'),
            navbar: () =>
                import ('../components/Navbar.vue')
        },
        meta: { requiresAuth: true }
    },
    {
        path: '/:catchAll(.*)',
        component: () =>
            import ('../views/404/index.vue')
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        function checkIfUserIsLoggedIn() {
            const userToken = JSON.parse(localStorage.getItem('admin_token'));
            const isLoggedIn = Boolean(userToken);

            return isLoggedIn;
        }
        const isLoggedIn = checkIfUserIsLoggedIn()
        if (!isLoggedIn) {
            next('/login');
        } else {
            next();
        }
    } else {
        next();
    }
})

export default router