import { faFont, faFilePdf, faCalendar, faHeart, faMagnet, faBuilding, faHandshake, faCircleQuestion, faInfo, faBuildingShield, faXmark, faThumbsUp, faEye, faFile, faTrash, faPenToSquare, faPlus, faChevronUp, faChevronDown, faAddressCard, faRightFromBracket, faUser, faUsers, faLink, faMask, faCircleInfo, faList, faNewspaper, faQuestion, faVideo, faEyeSlash, faPaperPlane, faComment, faUpload, faChartSimple } from "@fortawesome/free-solid-svg-icons";
export const icons = {
    faFont,
    faFilePdf,
    faCalendar,
    faHeart,
    faMagnet,
    faBuilding,
    faHandshake,
    faCircleQuestion,
    faInfo,
    faBuildingShield,
    faXmark,
    faThumbsUp,
    faEye,
    faFile,
    faTrash,
    faPenToSquare,
    faPlus,
    faChevronUp,
    faChevronDown,
    faAddressCard,
    faRightFromBracket,
    faUser,
    faUsers,
    faLink,
    faMask,
    faCircleInfo,
    faList,
    faNewspaper,
    faQuestion,
    faVideo,
    faEyeSlash,
    faPaperPlane,
    faComment,
    faUpload,
    faChartSimple
}